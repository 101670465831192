export const IS_DEBUG = false;

export const FOVY = 30;

export const VIEW_HEIGHT = 10;// [m]

export const UNIVERSE_SIZE = 100;// [m]
export const UNIVERSE_HEIGHT = 300;// [m]

export const OBJECT_Z = -20;// [m]

export const PARTICLE_SIZE = 1;// [m]

export const MORPHING_DURATION = 0.5;// [s]

export const SPACE_POINTS_QTY = 10000;
export const MORPHING_POINTS_QTY = 1000;

export const SPACE_COLOR_MULTIPLIER = 0.6;
export const MORPHING_COLOR_MULTIPLIER = 0.6;
export const MESH_SCALE_MULTIPLIER = 2;
export const OFFSET_WIDTH_MULTIPLIER = 1.2;
