import * as THREE from "three";

import { MeshSurfaceSampler } from 'three/examples/jsm/math/MeshSurfaceSampler';

export interface GeometryBufferData {
  position: Float32Array;
  color: Float32Array;
}

export function makeDataFromBox3(box3: THREE.Box3, pointsQty: number, colorMultiplier: number): GeometryBufferData {
  const position = new Float32Array(pointsQty * 3);
  const color = new Float32Array(pointsQty * 3);
  const v = box3.max.clone().sub(box3.min);
  const p = box3.min.clone();
  for (let i = 0; i < pointsQty; i += 1) {
    position[i * 3 + 0] = p.x + v.x * Math.random();
    position[i * 3 + 1] = p.y + v.y * Math.random();
    position[i * 3 + 2] = p.z + v.z * Math.random();
    color[i * 3 + 0] = (Math.random() * 0.5 + 0) * colorMultiplier;
    color[i * 3 + 1] = (Math.random() * 0.25 + 0.25) * colorMultiplier;
    color[i * 3 + 2] = (Math.random() * 0.25 + 0.25) * colorMultiplier;
  }
  return {
    position,
    color,
  };

}

export function makeSampledDataFromMesh(mesh: THREE.Mesh, pointsQty: number, colorMultiplier: number): GeometryBufferData {

  const position = new Float32Array(pointsQty * 3);
  const color = new Float32Array(pointsQty * 3);
  const sampler = new MeshSurfaceSampler(mesh).build();
  const positionElement = new THREE.Vector3();
  const normalElement = new THREE.Vector3();
  for (let i = 0; i < pointsQty; i += 1) {
    sampler.sample(positionElement, normalElement);
    position[i * 3 + 0] = positionElement.x;
    position[i * 3 + 1] = positionElement.y;
    position[i * 3 + 2] = positionElement.z;
    // console.log(normalElement.length());
    normalElement.normalize();
    color[i * 3 + 0] = (normalElement.x * 0.5 + 0 + 0.1) * colorMultiplier;
    color[i * 3 + 1] = (normalElement.y * 0.25 + 0.25 + 0.1) * colorMultiplier;
    color[i * 3 + 2] = (normalElement.z * 0.25 + 0.25 + 0.1) * colorMultiplier;
  }
  return {
    position,
    color,
  };
}