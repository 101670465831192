import * as THREE from "three";

export function calcCameraZ(height:number,fovyDeg:number):number{
  const fovy=THREE.MathUtils.degToRad(fovyDeg);
  const halfFovy=fovy/2;
  const t=Math.tan(halfFovy);
  const halfHeight=height/2;
  const z=halfHeight/t;
  return z;
}

export function calcCameraY(height:number,documentHeight:number,windowHeight:number,scrollY:number):number{
  const scrollableRange=Math.max(0,documentHeight-windowHeight);
  // 0割りを回避するため、windowHeightが1以下にならないようにする
  const y=(scrollableRange / 2 - scrollY) / Math.max(1,windowHeight) * height;
  return y;
}