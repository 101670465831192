
import * as THREE from "three";
import { GeometryBufferData } from "./_geometry_buffer_utils";
import { noise4d } from "./_noise";

interface ShapeData {
  index: number;
  positionAttribute: THREE.BufferAttribute;
  colorAttribute: THREE.BufferAttribute;
  weight: number;
}


export default class MorphingPoints extends THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>{
  customShader?: THREE.Shader;

  shapeDataList: ShapeData[] = [];

  previousShape1Index?: number;

  previousShape2Index?: number;

  constructor(geometry: THREE.BufferGeometry, material: THREE.PointsMaterial, geometryBufferDataList: GeometryBufferData[]) {
    super(geometry, material);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    material.onBeforeCompile = (shader: THREE.Shader, renderer: THREE.WebGLRenderer) => {
      this.customShader = shader;
      shader.uniforms.time = {
        value: 0,
      };
      shader.uniforms.noiseFactor = {
        value: 0,
      };
      shader.uniforms.weight = {
        value: 0,
      };
      shader.vertexShader = shader.vertexShader
        .replace("#include <common>", `
      #include <common>
      ${noise4d}
      uniform float time;
      uniform float noiseFactor;
      uniform float weight;
      attribute vec3 position2;
      attribute vec3 color2;
      `)
        .replace("#include <begin_vertex>", `
      vec3 transformed = mix(position,position2,weight);
      vec3 p = position * 10.0;
      transformed += vec3(snoise(vec4(p,time)),snoise(vec4(p,time+0.5)),snoise(vec4(p,time+1.0)))*noiseFactor;
      `)
        .replace("#include <color_vertex>", `
      vColor = mix(color,color2,weight);
      `)
        ;
      // console.log(shader.vertexShader);
    };

    if (geometryBufferDataList.length < 2) {
      throw new Error("geometryBufferDataList.length is less than 2");
    }

    this.shapeDataList = geometryBufferDataList.map((geometryBufferData, index) => {
      // const suffix=i==0?"":`${i+1}`;
      const positionAttribute = new THREE.BufferAttribute(geometryBufferData.position, 3);
      // geometry.setAttribute("position"+suffix,positionAttribute);
      const colorAttribute = new THREE.BufferAttribute(geometryBufferData.color, 3);
      // geometry.setAttribute("color"+suffix,colorAttribute);
      return {
        index,
        positionAttribute,
        colorAttribute,
        weight: 0,
      }
    });
    this.updateAttributes();
  }

  setNoiseFactor(noiseFactor: number) {
    const { customShader } = this;
    if (customShader) {
      customShader.uniforms.noiseFactor.value = noiseFactor;
    }
  }

  setWeight(index: number, weight: number) {
    if (!(0 <= index && index < this.shapeDataList.length)) {
      throw new Error("index out of range");
    }
    this.shapeDataList[index].weight = weight;
  }

  setTime(time: number) {
    const { customShader } = this;
    if (customShader) {
      customShader.uniforms.time.value = time;
    }
  }

  updateAttributes() {
    // 上位2件のみを反映する

    const sortedShapeDataList = Array.from(this.shapeDataList).sort((a, b) => -(a.weight - b.weight));
    if (!(this.previousShape1Index === sortedShapeDataList[0].index && this.previousShape2Index === sortedShapeDataList[1].index)) {
      const { geometry } = this;
      geometry.setAttribute("position", sortedShapeDataList[0].positionAttribute);
      geometry.setAttribute("color", sortedShapeDataList[0].colorAttribute);
      geometry.setAttribute("position2", sortedShapeDataList[1].positionAttribute);
      geometry.setAttribute("color2", sortedShapeDataList[1].colorAttribute);

      this.previousShape1Index = sortedShapeDataList[0].index;
      this.previousShape2Index = sortedShapeDataList[1].index;
    }
    const { customShader } = this;
    if (customShader) {
      customShader.uniforms.weight.value = sortedShapeDataList[1].weight;
    }
    // console.log(sortedAttributeDataList.map((e)=>e.weight));

  }

}