import * as THREE from "three";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import { Swiper, Navigation } from "swiper";

import Stats from "stats.js";

import ElementSizeObserver from './_ElementSizeObserver';
import MorphingPoints from './_MorphingPoints';

import { makeDataFromBox3, makeSampledDataFromMesh } from "./_geometry_buffer_utils";
import { FOVY, IS_DEBUG, MORPHING_DURATION, MORPHING_POINTS_QTY, OBJECT_Z, PARTICLE_SIZE, SPACE_POINTS_QTY, UNIVERSE_SIZE, UNIVERSE_HEIGHT, VIEW_HEIGHT, SPACE_COLOR_MULTIPLIER, MORPHING_COLOR_MULTIPLIER, MESH_SCALE_MULTIPLIER, OFFSET_WIDTH_MULTIPLIER } from "./_constants";
import { calcCameraY, calcCameraZ } from "./_three_scroll_utils";
import { loadMeshFromGLTFAsync, translateToCenter } from "./_three_utils";
import { map } from "./_math_utils";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

interface MorphingParams {
  phase: number;
  angularVelocity: number;
}

interface ThreeObjects {
  clock: THREE.Clock;
  renderer: THREE.WebGLRenderer;
  camera: THREE.PerspectiveCamera;
  scene: THREE.Scene;
  morphingParams: MorphingParams;
  morphingPointsMesh: MorphingPoints;

}


export default class HomeApp {

  stats: Stats;

  backgroundElement: HTMLDivElement;

  elementSizeObserver: ElementSizeObserver;

  swiperList: Swiper[] = [];

  threeObjects?: ThreeObjects;

  constructor() {


    this.stats = new Stats();
    this.stats.dom.style.display = IS_DEBUG ? "block" : "none";
    document.body.appendChild(this.stats.dom);

    const backgroundElement = document.querySelector<HTMLDivElement>(".l-background");
    if (!backgroundElement) {
      throw new Error("backgroundElement is null");
    }
    this.backgroundElement = backgroundElement;
    this.elementSizeObserver = new ElementSizeObserver({
      elementForSize: backgroundElement,
    });
    this.setupSwiper();

    this.setupThreeAsync().catch((error) => {
      console.error(error);
    }).then(() => {
      this.setupEvents();
    });


  }

  setupSwiper() {
    // .swiperの外に.swiper-button-nextを置くため、selectorでは混線する。elementで指定する
    // this.swiper = new Swiper('.swiper', {
    //   // configure Swiper to use modules
    //   // modules: [Navigation, Pagination],
    //   modules: [Navigation],
    //   navigation: {
    //     nextEl: ".swiper-button-next",
    //     prevEl: ".swiper-button-prev",
    //   },
    // });

    this.swiperList = Array.from(document.querySelectorAll<HTMLElement>(".p-article-furniture__swiper-background")).map((backgroundElement) => {
      const swiperElement = backgroundElement.querySelector<HTMLElement>(".swiper");
      if (!swiperElement) {
        throw new Error("swiperElement is null");
      }
      const nextElement = backgroundElement.querySelector<HTMLElement>(".swiper-button-next");
      if (!nextElement) {
        throw new Error("nextElement is null");
      }
      const prevElement = backgroundElement.querySelector<HTMLElement>(".swiper-button-prev");
      if (!prevElement) {
        throw new Error("prevElement is null");
      }
      return new Swiper(swiperElement, {
        modules: [Navigation],
        navigation: {
          nextEl: nextElement,
          prevEl: prevElement,
        },
      });

    });

  }

  async setupThreeAsync() {
    const size = this.elementSizeObserver.getSize();

    const clock = new THREE.Clock();

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(FOVY, size.width / size.height, 3, 1000);
    scene.add(camera);

    const renderer = new THREE.WebGLRenderer({
      alpha: false,
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(size.width, size.height);
    renderer.domElement.classList.add("l-background__view");
    this.backgroundElement.appendChild(renderer.domElement);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(10, 10, 10);
    scene.add(directionalLight);


    const momoMesh = await loadMeshFromGLTFAsync("./assets/models/momo.glb");
    translateToCenter(momoMesh.geometry);
    momoMesh.geometry.rotateZ(30 * THREE.MathUtils.DEG2RAD);
    momoMesh.geometry.scale(MESH_SCALE_MULTIPLIER, MESH_SCALE_MULTIPLIER, MESH_SCALE_MULTIPLIER);

    const chairMesh = await loadMeshFromGLTFAsync("./assets/models/chair.glb");
    translateToCenter(chairMesh.geometry);
    chairMesh.geometry.scale(5 * MESH_SCALE_MULTIPLIER, 5 * MESH_SCALE_MULTIPLIER, 5 * MESH_SCALE_MULTIPLIER);

    const benchMesh = await loadMeshFromGLTFAsync("./assets/models/bench.glb");
    translateToCenter(benchMesh.geometry);
    benchMesh.geometry.scale(2.5 * MESH_SCALE_MULTIPLIER, 2.5 * MESH_SCALE_MULTIPLIER, 2.5 * MESH_SCALE_MULTIPLIER);

    const tableMesh = await loadMeshFromGLTFAsync("./assets/models/table.glb");
    translateToCenter(tableMesh.geometry);
    tableMesh.geometry.scale(3 * MESH_SCALE_MULTIPLIER, 3 * MESH_SCALE_MULTIPLIER, 3 * MESH_SCALE_MULTIPLIER);

    const lowTableMesh = await loadMeshFromGLTFAsync("./assets/models/low-table.glb");
    translateToCenter(lowTableMesh.geometry);
    lowTableMesh.geometry.scale(3 * MESH_SCALE_MULTIPLIER, 3 * MESH_SCALE_MULTIPLIER, 3 * MESH_SCALE_MULTIPLIER);

    const shelfMesh = await loadMeshFromGLTFAsync("./assets/models/shelf.glb");
    translateToCenter(shelfMesh.geometry);
    shelfMesh.geometry.scale(3 * MESH_SCALE_MULTIPLIER, 3 * MESH_SCALE_MULTIPLIER, 3 * MESH_SCALE_MULTIPLIER);

    const planterMesh = await loadMeshFromGLTFAsync("./assets/models/planter.glb");
    translateToCenter(planterMesh.geometry);
    planterMesh.geometry.scale(5 * MESH_SCALE_MULTIPLIER, 5 * MESH_SCALE_MULTIPLIER, 5 * MESH_SCALE_MULTIPLIER);

    const flowerBaseMesh = await loadMeshFromGLTFAsync("./assets/models/flower-base.glb");
    translateToCenter(flowerBaseMesh.geometry);
    flowerBaseMesh.geometry.scale(4 * MESH_SCALE_MULTIPLIER, 4 * MESH_SCALE_MULTIPLIER, 4 * MESH_SCALE_MULTIPLIER);

    const spacePointsMesh = (() => {
      const geometry = new THREE.BufferGeometry();
      const box3 = new THREE.Box3(new THREE.Vector3(UNIVERSE_SIZE * -0.5, UNIVERSE_HEIGHT * -0.5, UNIVERSE_SIZE * -0.5), new THREE.Vector3(UNIVERSE_SIZE * 0.5, UNIVERSE_HEIGHT * 0.5, UNIVERSE_SIZE * 0.5));
      const geometryBufferData = makeDataFromBox3(box3, SPACE_POINTS_QTY, SPACE_COLOR_MULTIPLIER);

      const positionAttribute = new THREE.BufferAttribute(geometryBufferData.position, 3);
      geometry.setAttribute("position", positionAttribute);
      const colorAttribute = new THREE.BufferAttribute(geometryBufferData.color, 3);
      geometry.setAttribute("color", colorAttribute);


      // const sprite = new THREE.TextureLoader().load("./assets/textures/sprites/disc.png");
      const sprite = new THREE.TextureLoader().load("./assets/textures/sprites/star-flare.png");
      const material = new THREE.PointsMaterial({
        map: sprite,
        transparent: true,
        size: PARTICLE_SIZE,
        sizeAttenuation: true,
        blending: THREE.AdditiveBlending,
        depthWrite: false,
        vertexColors: true,
      });
      const mesh = new THREE.Points(geometry, material);
      return mesh;

    })();

    spacePointsMesh.position.z = OBJECT_Z;
    scene.add(spacePointsMesh);

    const morphingPointsMesh = (() => {


      const geometry = new THREE.BufferGeometry();
      const box3 = new THREE.Box3(new THREE.Vector3(UNIVERSE_SIZE * -0.5, UNIVERSE_HEIGHT * -0.5, UNIVERSE_SIZE * -0.5), new THREE.Vector3(UNIVERSE_SIZE * 0.5, UNIVERSE_HEIGHT * 0.5, UNIVERSE_SIZE * 0.5));

      const geometryBufferDataList = [
        makeDataFromBox3(box3, MORPHING_POINTS_QTY, SPACE_COLOR_MULTIPLIER),
        makeSampledDataFromMesh(benchMesh, MORPHING_POINTS_QTY, MORPHING_COLOR_MULTIPLIER),
        makeSampledDataFromMesh(chairMesh, MORPHING_POINTS_QTY, MORPHING_COLOR_MULTIPLIER),
        makeSampledDataFromMesh(tableMesh, MORPHING_POINTS_QTY, MORPHING_COLOR_MULTIPLIER),
        makeSampledDataFromMesh(shelfMesh, MORPHING_POINTS_QTY, MORPHING_COLOR_MULTIPLIER),
        makeSampledDataFromMesh(planterMesh, MORPHING_POINTS_QTY, MORPHING_COLOR_MULTIPLIER),
        makeSampledDataFromMesh(flowerBaseMesh, MORPHING_POINTS_QTY, MORPHING_COLOR_MULTIPLIER),
        makeSampledDataFromMesh(lowTableMesh, MORPHING_POINTS_QTY, MORPHING_COLOR_MULTIPLIER),
        makeSampledDataFromMesh(momoMesh, MORPHING_POINTS_QTY, MORPHING_COLOR_MULTIPLIER),
      ];


      // const sprite = new THREE.TextureLoader().load( "./assets/textures/sprites/circle.png" );
      // const sprite = new THREE.TextureLoader().load( "./assets/textures/sprites/snowflake2.png" );
      // const sprite = new THREE.TextureLoader().load( "./assets/textures/sprites/ball.png" );
      // const sprite = new THREE.TextureLoader().load("./assets/textures/sprites/disc.png");
      const sprite = new THREE.TextureLoader().load("./assets/textures/sprites/star-flare.png");

      const material = new THREE.PointsMaterial({
        map: sprite,
        transparent: true,
        size: PARTICLE_SIZE,
        sizeAttenuation: true,
        blending: THREE.AdditiveBlending,
        depthWrite: false,
        vertexColors: true,
      });

      const mesh = new MorphingPoints(geometry, material, geometryBufferDataList);



      return mesh;
    })();

    morphingPointsMesh.position.z = OBJECT_Z;
    scene.add(morphingPointsMesh);






    // const ground=(()=>{
    //   const geometry = new THREE.BoxGeometry( 50, 1, 50 );
    //   const material = new THREE.MeshBasicMaterial( { color: 0x80ff80 } );
    //   const mesh = new THREE.Mesh( geometry, material );
    //   mesh.position.set(0,-0.5,0);
    //   return mesh;
    // })();
    // scene.add( ground );

    const ANGULAR_VELOCITY = 45 * THREE.MathUtils.DEG2RAD;
    const morphingParams: MorphingParams = {
      phase: 0,
      angularVelocity: 0,
    }
    const morphingTimeline = gsap.timeline({
      paused: true,
    });
    morphingTimeline.addLabel("phase0");
    morphingTimeline.addPause();
    morphingTimeline.to(morphingParams, {
      phase: 1,
      angularVelocity: ANGULAR_VELOCITY,
      duration: MORPHING_DURATION,
      ease: "power2.inOut",
    });
    morphingTimeline.addLabel("phase1");
    morphingTimeline.addPause();
    morphingTimeline.to(morphingParams, {
      phase: 2,
      angularVelocity: ANGULAR_VELOCITY,
      duration: MORPHING_DURATION,
      ease: "power2.inOut",
    });
    morphingTimeline.addLabel("phase2");
    morphingTimeline.addPause();
    morphingTimeline.to(morphingParams, {
      phase: 3,
      angularVelocity: ANGULAR_VELOCITY,
      duration: MORPHING_DURATION,
      ease: "power2.inOut",
    });
    morphingTimeline.addLabel("phase3");
    morphingTimeline.addPause();
    morphingTimeline.to(morphingParams, {
      phase: 4,
      angularVelocity: ANGULAR_VELOCITY,
      duration: MORPHING_DURATION,
      ease: "power2.inOut",
    });
    morphingTimeline.addLabel("phase4");
    morphingTimeline.addPause();
    morphingTimeline.to(morphingParams, {
      phase: 5,
      angularVelocity: ANGULAR_VELOCITY,
      duration: MORPHING_DURATION,
      ease: "power2.inOut",
    });
    morphingTimeline.addLabel("phase5");
    morphingTimeline.addPause();
    morphingTimeline.to(morphingParams, {
      phase: 6,
      angularVelocity: ANGULAR_VELOCITY,
      duration: MORPHING_DURATION,
      ease: "power2.inOut",
    });
    morphingTimeline.addLabel("phase6");
    morphingTimeline.addPause();
    morphingTimeline.to(morphingParams, {
      phase: 7,
      angularVelocity: ANGULAR_VELOCITY,
      duration: MORPHING_DURATION,
      ease: "power2.inOut",
    });
    morphingTimeline.addLabel("phase7");
    morphingTimeline.addPause();
    morphingTimeline.to(morphingParams, {
      phase: 8,
      angularVelocity: ANGULAR_VELOCITY,
      duration: MORPHING_DURATION,
      ease: "power2.inOut",
    });
    morphingTimeline.addLabel("phase8");
    morphingTimeline.addPause();
    morphingTimeline.to(morphingParams, {
      phase: 9,
      angularVelocity: 0,
      duration: MORPHING_DURATION,
      ease: "power2.inOut",
    });
    morphingTimeline.addLabel("phase9");
    morphingTimeline.addPause();

    ScrollTrigger.create({
      trigger: ".p-article-furniture--01",
      start: "top center",
      toggleActions: "restart none none reverse",
      markers: IS_DEBUG,
      onEnter: (self) => {
        if (IS_DEBUG) {
          console.log(`onEnter ${self.trigger?.className}`);
        }
        morphingTimeline.play("phase0");
      },
      onLeaveBack: (self) => {
        if (IS_DEBUG) {
          console.log(`onLeaveBack ${self.trigger?.className}`);
        }
        morphingTimeline.reverse("phase1");
      },
    });
    ScrollTrigger.create({
      trigger: ".p-article-furniture--02",
      start: "top center",
      toggleActions: "restart none none reverse",
      markers: IS_DEBUG,
      onEnter: (self) => {
        if (IS_DEBUG) {
          console.log(`onEnter ${self.trigger?.className}`);
        }
        morphingTimeline.play("phase1");
      },
      onLeaveBack: (self) => {
        if (IS_DEBUG) {
          console.log(`onLeaveBack ${self.trigger?.className}`);
        }
        morphingTimeline.reverse("phase2");
      },
    });
    ScrollTrigger.create({
      trigger: ".p-article-furniture--03",
      start: "top center",
      toggleActions: "restart none none reverse",
      markers: IS_DEBUG,
      onEnter: (self) => {
        if (IS_DEBUG) {
          console.log(`onEnter ${self.trigger?.className}`);
        }
        morphingTimeline.play("phase2");
      },
      onLeaveBack: (self) => {
        if (IS_DEBUG) {
          console.log(`onLeaveBack ${self.trigger?.className}`);
        }
        morphingTimeline.reverse("phase3");
      },
    });
    ScrollTrigger.create({
      trigger: ".p-article-furniture--04",
      start: "top center",
      toggleActions: "restart none none reverse",
      markers: IS_DEBUG,
      onEnter: (self) => {
        if (IS_DEBUG) {
          console.log(`onEnter ${self.trigger?.className}`);
        }
        morphingTimeline.play("phase3");
      },
      onLeaveBack: (self) => {
        if (IS_DEBUG) {
          console.log(`onLeaveBack ${self.trigger?.className}`);
        }
        morphingTimeline.reverse("phase4");
      },
    });
    ScrollTrigger.create({
      trigger: ".p-article-furniture--05",
      start: "top center",
      toggleActions: "restart none none reverse",
      markers: IS_DEBUG,
      onEnter: (self) => {
        if (IS_DEBUG) {
          console.log(`onEnter ${self.trigger?.className}`);
        }
        morphingTimeline.play("phase4");
      },
      onLeaveBack: (self) => {
        if (IS_DEBUG) {
          console.log(`onLeaveBack ${self.trigger?.className}`);
        }
        morphingTimeline.reverse("phase5");
      },
    });
    ScrollTrigger.create({
      trigger: ".p-article-furniture--06",
      start: "top center",
      toggleActions: "restart none none reverse",
      markers: IS_DEBUG,
      onEnter: (self) => {
        if (IS_DEBUG) {
          console.log(`onEnter ${self.trigger?.className}`);
        }
        morphingTimeline.play("phase5");
      },
      onLeaveBack: (self) => {
        if (IS_DEBUG) {
          console.log(`onLeaveBack ${self.trigger?.className}`);
        }
        morphingTimeline.reverse("phase6");
      },
    });
    ScrollTrigger.create({
      trigger: ".p-article-furniture--07",
      start: "top center",
      toggleActions: "restart none none reverse",
      markers: IS_DEBUG,
      onEnter: (self) => {
        if (IS_DEBUG) {
          console.log(`onEnter ${self.trigger?.className}`);
        }
        morphingTimeline.play("phase6");
      },
      onLeaveBack: (self) => {
        if (IS_DEBUG) {
          console.log(`onLeaveBack ${self.trigger?.className}`);
        }
        morphingTimeline.reverse("phase7");
      },
    });
    ScrollTrigger.create({
      trigger: ".p-section-first-flow",
      start: "top center",
      toggleActions: "restart none none reverse",
      markers: IS_DEBUG,
      onEnter: (self) => {
        if (IS_DEBUG) {
          console.log(`onEnter ${self.trigger?.className}`);
        }
        morphingTimeline.play("phase7");
      },
      onLeaveBack: (self) => {
        if (IS_DEBUG) {
          console.log(`onLeaveBack ${self.trigger?.className}`);
        }
        morphingTimeline.reverse("phase8");
      },
    });
    ScrollTrigger.create({
      trigger: ".p-section-contact",
      start: "top center",
      toggleActions: "restart none none reverse",
      markers: IS_DEBUG,
      onEnter: (self) => {
        if (IS_DEBUG) {
          console.log(`onEnter ${self.trigger?.className}`);
        }
        morphingTimeline.play("phase8");
      },
      onLeaveBack: (self) => {
        if (IS_DEBUG) {
          console.log(`onLeaveBack ${self.trigger?.className}`);
        }
        morphingTimeline.reverse("phase9");
      },
    });




    this.threeObjects = {
      clock,
      renderer,
      camera,
      scene,
      morphingParams,
      morphingPointsMesh,
    };
  }

  setupEvents() {
    this.elementSizeObserver.onResize = this.onResize.bind(this);

    const animate = () => {
      this.stats.begin();
      requestAnimationFrame(animate);
      this.onTick();
      this.stats.end();
    }

    animate();

  }

  onResize() {
    if (!this.threeObjects) {
      throw new Error("this.threeObjects is null");
    }
    const { renderer, camera } = this.threeObjects;
    const size = this.elementSizeObserver.getSize();
    if (IS_DEBUG) {
      console.log("onResize", size.width, size.height);
    }
    renderer.setSize(size.width, size.height);

    const cameraZ = calcCameraZ(VIEW_HEIGHT, FOVY);
    camera.position.z = cameraZ;
    // console.log("cameraZ",cameraZ);
    camera.near = cameraZ;

    camera.aspect = size.width / size.height;
    camera.updateProjectionMatrix();

  }

  onTick() {
    if (!this.threeObjects) {
      throw new Error("this.threeObjects is null");
    }
    const { clock, renderer, camera, scene, morphingParams, morphingPointsMesh } = this.threeObjects;
    this.elementSizeObserver.update();
    const dt = clock.getDelta();
    const time = clock.getElapsedTime();

    const size = this.elementSizeObserver.getSize();

    const documentHeight = document.body.clientHeight;
    // if (IS_DEBUG) {
    //   console.log(documentHeight);
    // }

    const cameraY = calcCameraY(VIEW_HEIGHT, documentHeight, size.height, window.scrollY);
    camera.position.y = cameraY;
    // console.log("cameraY",cameraY);

    let offsetYWeight = 0;
    let offsetXWeight = 0;

    // console.log(morphingParams.phase);
    if (morphingParams.phase <= 1.0) {
      const r = morphingParams.phase;
      morphingPointsMesh.setWeight(0, 1 - r);
      morphingPointsMesh.setWeight(1, r);
      morphingPointsMesh.setWeight(2, 0);
      morphingPointsMesh.setWeight(3, 0);
      morphingPointsMesh.setWeight(4, 0);
      morphingPointsMesh.setWeight(5, 0);
      morphingPointsMesh.setWeight(6, 0);
      morphingPointsMesh.setWeight(7, 0);
      morphingPointsMesh.setWeight(8, 0);
      morphingPointsMesh.setNoiseFactor(r * 0.1);
      offsetYWeight = r;
      offsetXWeight = map(r, 0, 1, 0, 1);
    } else if (morphingParams.phase <= 2.0) {
      const r = morphingParams.phase - 1;
      morphingPointsMesh.setWeight(0, 0);
      morphingPointsMesh.setWeight(1, 1 - r);
      morphingPointsMesh.setWeight(2, r);
      morphingPointsMesh.setWeight(3, 0);
      morphingPointsMesh.setWeight(4, 0);
      morphingPointsMesh.setWeight(5, 0);
      morphingPointsMesh.setWeight(6, 0);
      morphingPointsMesh.setWeight(7, 0);
      morphingPointsMesh.setWeight(8, 0);
      morphingPointsMesh.setNoiseFactor(0.1);
      offsetYWeight = 1;
      offsetXWeight = map(r, 0, 1, 1, -1);
    } else if (morphingParams.phase <= 3.0) {
      const r = morphingParams.phase - 2;
      morphingPointsMesh.setWeight(0, 0);
      morphingPointsMesh.setWeight(1, 0);
      morphingPointsMesh.setWeight(2, 1 - r);
      morphingPointsMesh.setWeight(3, r);
      morphingPointsMesh.setWeight(4, 0);
      morphingPointsMesh.setWeight(5, 0);
      morphingPointsMesh.setWeight(6, 0);
      morphingPointsMesh.setWeight(7, 0);
      morphingPointsMesh.setWeight(8, 0);
      morphingPointsMesh.setNoiseFactor(0.1);
      offsetYWeight = 1;
      offsetXWeight = map(r, 0, 1, -1, 1);
    } else if (morphingParams.phase <= 4.0) {
      const r = morphingParams.phase - 3;
      morphingPointsMesh.setWeight(0, 0);
      morphingPointsMesh.setWeight(1, 0);
      morphingPointsMesh.setWeight(2, 0);
      morphingPointsMesh.setWeight(3, 1 - r);
      morphingPointsMesh.setWeight(4, r);
      morphingPointsMesh.setWeight(5, 0);
      morphingPointsMesh.setWeight(6, 0);
      morphingPointsMesh.setWeight(7, 0);
      morphingPointsMesh.setWeight(8, 0);
      morphingPointsMesh.setNoiseFactor(0.1);
      offsetYWeight = 1;
      offsetXWeight = map(r, 0, 1, 1, -1);
    } else if (morphingParams.phase <= 5.0) {
      const r = morphingParams.phase - 4;
      morphingPointsMesh.setWeight(0, 0);
      morphingPointsMesh.setWeight(1, 0);
      morphingPointsMesh.setWeight(2, 0);
      morphingPointsMesh.setWeight(3, 0);
      morphingPointsMesh.setWeight(4, 1 - r);
      morphingPointsMesh.setWeight(5, r);
      morphingPointsMesh.setWeight(6, 0);
      morphingPointsMesh.setWeight(7, 0);
      morphingPointsMesh.setWeight(8, 0);
      morphingPointsMesh.setNoiseFactor(0.1);
      offsetYWeight = 1;
      offsetXWeight = map(r, 0, 1, -1, 1);
    } else if (morphingParams.phase <= 6.0) {
      const r = morphingParams.phase - 5;
      morphingPointsMesh.setWeight(0, 0);
      morphingPointsMesh.setWeight(1, 0);
      morphingPointsMesh.setWeight(2, 0);
      morphingPointsMesh.setWeight(3, 0);
      morphingPointsMesh.setWeight(4, 0);
      morphingPointsMesh.setWeight(5, 1 - r);
      morphingPointsMesh.setWeight(6, r);
      morphingPointsMesh.setWeight(7, 0);
      morphingPointsMesh.setWeight(8, 0);
      morphingPointsMesh.setNoiseFactor(0.1);
      offsetYWeight = 1;
      offsetXWeight = map(r, 0, 1, 1, -1);
    } else if (morphingParams.phase <= 7.0) {
      const r = morphingParams.phase - 6;
      morphingPointsMesh.setWeight(0, 0);
      morphingPointsMesh.setWeight(1, 0);
      morphingPointsMesh.setWeight(2, 0);
      morphingPointsMesh.setWeight(3, 0);
      morphingPointsMesh.setWeight(4, 0);
      morphingPointsMesh.setWeight(5, 0);
      morphingPointsMesh.setWeight(6, 1 - r);
      morphingPointsMesh.setWeight(7, r);
      morphingPointsMesh.setWeight(8, 0);
      morphingPointsMesh.setNoiseFactor(0.1);
      offsetYWeight = 1;
      offsetXWeight = map(r, 0, 1, -1, 1);
    } else if (morphingParams.phase <= 8.0) {
      const r = morphingParams.phase - 7;
      morphingPointsMesh.setWeight(0, 0);
      morphingPointsMesh.setWeight(1, 0);
      morphingPointsMesh.setWeight(2, 0);
      morphingPointsMesh.setWeight(3, 0);
      morphingPointsMesh.setWeight(4, 0);
      morphingPointsMesh.setWeight(5, 0);
      morphingPointsMesh.setWeight(6, 0);
      morphingPointsMesh.setWeight(7, 1 - r);
      morphingPointsMesh.setWeight(8, r);
      morphingPointsMesh.setNoiseFactor(0.1);
      offsetYWeight = 1;
      offsetXWeight = map(r, 0, 1, 1, -1);
    } else if (morphingParams.phase <= 9.0) {
      const r = morphingParams.phase - 8;
      morphingPointsMesh.setWeight(0, r);
      morphingPointsMesh.setWeight(1, 0);
      morphingPointsMesh.setWeight(2, 0);
      morphingPointsMesh.setWeight(3, 0);
      morphingPointsMesh.setWeight(4, 0);
      morphingPointsMesh.setWeight(5, 0);
      morphingPointsMesh.setWeight(6, 0);
      morphingPointsMesh.setWeight(7, 0);
      morphingPointsMesh.setWeight(8, 1 - r);
      morphingPointsMesh.setNoiseFactor((1 - r) * 0.1);
      offsetYWeight = 1 - r;
      offsetXWeight = map(r, 0, 1, -1, 0);
    } else {
      // DO NOTHING
    }
    morphingPointsMesh.rotation.y += morphingParams.angularVelocity * dt;
    morphingPointsMesh.setTime(time);
    morphingPointsMesh.position.y = cameraY * offsetYWeight;
    const viewWidth = VIEW_HEIGHT / size.height * size.width;
    const offsetWidth = viewWidth * 0.5 * OFFSET_WIDTH_MULTIPLIER;
    morphingPointsMesh.position.x = offsetWidth * offsetXWeight;

    morphingPointsMesh.updateAttributes();

    // controls.update();
    renderer.render(scene, camera);

  }


}