import * as THREE from "three";

import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export async function loadMeshFromGLTFAsync(url: string): Promise<THREE.Mesh> {
  return new Promise<THREE.Mesh>((resolve, reject) => {
    const loader = new GLTFLoader();
    loader.load(url, (gltf: GLTF) => {
      gltf.scene.traverse((object3D: THREE.Object3D) => {
        if (object3D instanceof THREE.Mesh) {
          resolve(object3D)
        }
      });
      // 先にresolveされている場合は無視される
      reject(new Error("mesh not found"));
    }, undefined, reject);
  });
}

export function translateToCenter(geometry: THREE.BufferGeometry): void {
  geometry.computeBoundingBox();
  if (!geometry.boundingBox) {
    throw new Error("boundingBox is null");
  }
  const center = new THREE.Vector3();
  geometry.boundingBox.getCenter(center);
  geometry.translate(-center.x, -center.y, -center.z);
}
